<div class="pos-wrap scrollable-container" [nbSpinner]="loading" [ngClass]="{'is-fullscreen': isFullscreenMode}" #commercePosGui>
    <nb-card class="small-header smart-table" class="dialog-wrap {{inputMode}}" [formGroup]="orderForm" [ngClass]="{'pos-returns': orderForm['voucherType'] == 'COMMERCEPOSRETURN'}">
        <nb-card-body>
            <div class="pos" style="width: 100%; height: 100%; display: flex; flex-direction: column;">
                <div class="pos-container scrollable-container" style="flex: 1; display: flex; flex-direction: row; width: 100%; overflow: hidden;">
                    <div class="order" style="display: flex; flex-direction: column; flex: 1;">

                        <div class="pos-search" style="padding: 0.5rem; padding-bottom: 0;">
                            <div class="pos-search-input-wrap" style="flex: 1; position: relative;">
                                <div class="row">
                                    <div class="col-is-6 col-md-3">
                                        <ngx-form-group [formGroup]="orderForm" name="Object" label="Khách hàng" [allowCopy]="false" [required]="true" [hideLabel]="true">
                                            <ngx-select2 #customerEle class="" formControlName="Object" [select2Option]="select2OptionForContact" (selectChange)="onObjectChange(orderForm, $event)"></ngx-select2>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-is-6 col-md-3">
                                        <ngx-form-group [formGroup]="orderForm" name="ObjectName" label="Tên" [allowCopy]="false" [required]="true" [hideLabel]="true">
                                            <input id="ObjectName" #ObjectName formControlName="ObjectName" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" fieldSize="medium" fullWidth placeholder="Tên khách hàng..." class="pos-contact-field-input">
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-is-6 col-md-3">
                                        <ngx-form-group [formGroup]="orderForm" name="ObjectPhone" label="Số điện thoại" [allowCopy]="false" [required]="true" [hideLabel]="true">
                                            <input id="ObjectPhone" #ObjectPhone formControlName="ObjectPhone" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onObjectPhoneInput(orderForm, $event)" type="text" nbInput fieldSize="medium" fullWidth placeholder="Số điện thoại..." class="pos-contact-field-input">
                                            <nb-icon role="link" icon="phone-call" status="danger" style="position: absolute; right: 0.5rem; top: 0.6rem; cursor: pointer;" (click)="click2call(orderForm)" nbTooltip="Gọi cho khách bằng số nội bộ"></nb-icon>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-is-6 col-md-3">
                                        <ngx-form-group [formGroup]="orderForm" name="ObjectAddress" label="Địa chỉ" [allowCopy]="false" [required]="true" [hideLabel]="true">
                                            <input id="ObjectAddress" #ObjectAddress formControlName="ObjectAddress" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" fieldSize="medium" fullWidth placeholder="Địa chỉ..." class="pos-contact-field-input" style="padding-right: 2rem">
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pos-search" style="display: flex; flex-direction: row; padding: 0.5rem; padding-bottom: 0;">
                            <div class="pos-search-input-wrap" style="flex: 1; position: relative;">
                                <input #Search id="posSearchInput" (focus)="autoBlur($event, 30000, 'posSearchInput')" (blur)="clearAutoBlur($event, 'posSearchInput')" (keydown)="onSearchInputKeydown($event)" (keyup)="onSearchInputKeyup($event)" type="text" nbInput status="{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? 'danger' : 'success'}}" fieldSize="large" fullWidth [placeholder]="searchInputPlaceholder || 'F3 - Tìm hàng hóa theo số truy xuất, số nhận thức, sku, id, tên...'" class="pos-search-input" autocomplete="off">
                                <div #searchResultsRef id="searchResults" style="
                                        position: fixed;
                                        z-index: 1;
                                        left: 0px;
                                        top: 114px;
                                        background-color: var(--ck-color-base-background);
                                        border-radius: 5px;
                                    " [style.height]="searchResults && searchResults.length > 0 ? '80vh' : '1px'" [style.width]="searchResults && searchResults.length > 0 ? '100vw' : '1px'">
                                    <!-- <div style=""> -->
                                    <cdk-virtual-scroll-viewport #searchListViewport [itemSize]="200" style="height: 80vh;" class="scrollable-container">
                                        <div class="pos-search-result-item" *cdkVirtualFor="let item of searchResults; let i = index;" [ngClass]="{
                                                    'active': i == searchResultActiveIndex,
                                                    'not-same-pre': !searchResults[i-1] || item.Sku != searchResults[i-1].Sku,
                                                    'not-same-next': !searchResults[i+1] || item.Sku != searchResults[i+1].Sku
                                                }" (click)="onChooseProduct(item)">
                                            <div class="image" style="
                                                    background-repeat: no-repeat; 
                                                    background-size: cover;
                                                    background-position: center;
                                                    width: 8rem; 
                                                    height: 100%;
                                                    margin-right: 0.5rem" [style.backgroundImage]="'url('+item.FeaturePicture?.SmallImage+')'"></div>
                                            <div style="flex: 1;">
                                                <div style="font-weight: bold;">{{item.text}}</div>
                                                <div style="margin-top: 0.5rem;">SKU: {{item.Sku}}</div>
                                                <!-- <div>ĐVT: {{(item.Unit || item.WarehouseUnit) | objecttext}}</div> -->
                                                <div style="font-weight: bold; font-size: 2rem; line-height: 2rem;">
                                                    <span *ngIf="item.DiscountPercent > 0 else notDiscount">
                                                        <span style="color: var(--purple); text-decoration: line-through; font-size: 2rem;">{{item.ListedPrice | currency:'VND'}}</span>
                                                        <span style="color: var(--red); font-size: 1rem;"> -{{item.DiscountPercent | number:'1.0-2'}}%</span> =
                                                        <span style="color: var(--danger); font-size:1.5rem;">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base); font-size:1.5rem;">/</span>
                                                        <span style="color: var(--orange); font-size:1.5rem;">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span>
                                                    </span>
                                                    <ng-template #notDiscount>
                                                        <span style="color: var(--danger); font-size:2rem;">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base)">/</span>
                                                        <span style="color: var(--orange); font-size:2rem;">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span>
                                                    </ng-template>
                                                </div>
                                                <div>Vị trí: {{item.Container?.WarehouseName}}/{{item.Container?.ShelfName}}/{{item.Container?.FindOrder || item.Container?.ContainerFindOrder}}, <span style="white-space: nowrap;">Tồn kho: {{ item.Inventory === null && 'đang kiểm tra...' || (item.Inventory | number)}}</span></div>
                                                <!-- <div style="font-weight: bold; font-size: 2rem; line-height: 2rem;">Giá: <span style="color: var(--danger);">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base)">/</span><span style="color: var(--orange)">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span> -->
                                                <!-- <span style="font-size: 1.2rem; line-height: 1.2rem;color: var(---ck-color-base)">{{item.PriceOfBaseUnitText}}</span> -->
                                                <!-- </div> -->
                                                <div *ngIf="item.DiscountPercent > 0" style="font-weight: bold;">{{item.DiscountDescription}}</div>
                                            </div>
                                        </div>
                                    </cdk-virtual-scroll-viewport>
                                </div>
                            </div>
                            <div class="pos-search-button-wrap">
                                <button (click)="chooseProduct()" nbButton status="{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? 'danger' : 'success'}}" class="pos-search-button" size="large" style="margin-left: 1rem; width: 3.3rem;">
                                    <nb-icon icon="external-link-outline"></nb-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="orderForm['voucherType'] == 'COMMERCEPOSRETURN'" style="padding: 0.5rem; color: var(--danger); font-weight: bold;">BẠN ĐANG THAO TÁC TRÊN PHIẾU TRẢ HÀNG {{orderForm.value?.Order ? 'CHO ĐƠN HÀNG' + ' ' + orderForm.value?.Order : ''}}</div>
                        <div *ngIf="orderForm.value?.State == 'UNRECORDED'" style="padding: 0.5rem; color: var(--danger); font-weight: bold;">PHIẾU ĐÃ HỦY</div>
                        <div *ngIf="orderForm.value?.RelativeVouchers && orderForm.value?.RelativeVouchers?.length > 0" style="padding: 1rem; color: var(--primary); font-weight: bold;">
                            <div>Chứng từ liên quan:</div>
                            <div style="cursor: pointer;" class="tag" *ngFor="let relativeVoucher of orderForm.value?.RelativeVouchers" (click)="preview(relativeVoucher.type+'80', relativeVoucher.id)" nbTooltip="{{relativeVoucher.type}}: {{relativeVoucher.text}}">{{relativeVoucher.id}}</div>
                        </div>

                        <div class="pos-order-detail scrollable-container" style="flex: 1; padding: 0.5rem;">
                            <table style="width: 100%; font-size: 1rem; font-weight: bold;">
                                <thead>
                                    <td style="text-align: center;">#</td>
                                    <td style=" white-space: nowrap;">Hàng hóa</td>
                                    <td style="text-align: center;">(-)</td>
                                    <td style="text-align: right;"><span style="white-space: nowrap">Số lượng</span> X <span style="white-space: nowrap">Đơn giá</span><br><span style="white-space: nowrap">= Thành tiền</span></td>
                                    <td style="text-align: center;">(+)</td>
                                </thead>
                                <tbody #orderDetailTable [sortablejs]="getDetails(orderForm)" [sortablejsOptions]="sortablejsOptions">
                                    <tr *ngFor="let detail of getDetails(orderForm).controls; let i=index" [formGroup]="detail" [ngClass]="{'detail-active': detail['isActive']}" [id]="'detail-'+index" (click)="activeDetail(orderForm, detail, index)">
                                        <td style="text-align: center" class="sorting-handle">
                                            <div style="display: flex; flex-direction: column;">
                                                <div>
                                                    <button nbButton status="danger" size="large" style="margin: 0.2rem" (click)="removeDetail(orderForm, i)">
                                                        <nb-icon icon="close-outline"></nb-icon>
                                                    </button>
                                                    <button nbButton status="basic" size="large" [outline]="true" style="margin: 0.2rem">
                                                        <nb-icon icon="move-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div style="font-size: 2rem; padding: 1rem;">{{i+1}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="display: flex; flex-direction: row;">
                                                <div style="" class="sorting-handle">
                                                    <!-- <img (click)="previewGoodsThumbnail(detail)" class="pos-product-image" src="{{detail.value.Image?.SmallImage}}" [ngStyle]="{'height': ((12 - getDetails(orderForm).controls.length) > 4 ? (12 - getDetails(orderForm).controls.length) : 5)+'rem'}"> -->
                                                    <div *ngIf="detail.value.Image?.SmallImage" style="width: 8rem; height: 10rem; background-repeat: no-repeat; background-position: center; background-size: cover; cursor: zoom-in;" [ngStyle]="{'backgroundImage': 'url('+detail.value.Image?.SmallImage+')'}" (click)="previewGoodsThumbnail(detail)"></div>
                                                    <!-- <img (click)="previewGoodsThumbnail(detail)" class="pos-product-image" src="{{detail.value.Image?.SmallImage}}" style="height: 10rem"> -->
                                                </div>
                                                <div style="min-width: 10rem; flex: 1; padding-left: 0.5rem;">{{detail.value.Description | objecttext}} - SKU: {{detail.value.Sku}}<br>
                                                    <ng-container *ngIf="detail.value.Variants && detail.value.Variants.length > 0">
                                                        <div>
                                                            <span style="white-space: nowrap;" *ngFor="let variant of detail.value.Variants">{{variant | objecttext}}: {{variant.value}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <div style="font-weight: normal;">
                                                        Vị trí: {{detail.value.Container?.WarehouseName}}/{{detail.value.Container?.ShelfName}}/{{detail.value?.FindOrder}}, <ng-container *ngIf="detail.value?.Inventory">Tồn kho: {{detail.value.Inventory}}</ng-container>
                                                        &nbsp;<a class="link" href="#" (click)="outOfStockReport(detail.value) && false" style="font-style: italic;">báo hết hàng</a>
                                                    </div>
                                                    <div style="font-weight: normal; font-style: italic;">
                                                        <span *ngIf="detail.value.DiscountPercent > 0">{{detail.value.DiscountDescription}}</span>
                                                    </div>
                                                    <div style="font-weight: normal;">
                                                        <span *ngIf="detail.value.Publisher">CTV: {{detail.value.Publisher.PublisherRefId}}</span>
                                                    </div>
                                                </div>

                                                <div *ngIf="detail.value?.RelativeVouchers && detail.value?.RelativeVouchers?.length > 0">
                                                    <div class="tag" *ngFor="let relativeVoucher of detail.value?.RelativeVouchers" nbTooltip="{{relativeVoucher.type}}: {{relativeVoucher.text}}">{{relativeVoucher.id}}</div>
                                                </div>

                                            </div>
                                        </td>
                                        <td style="text-align: center" class="sorting-handle">
                                            <button nbButton status="warning" size="large" style="margin: 0.2rem" (click)="onDecreaseQuantityClick(orderForm, detail)">
                                                <nb-icon icon="minus-outline"></nb-icon>
                                            </button>
                                        </td>
                                        <td style="text-align: right;">
                                            <input class="pos-quantity" nbInput size="large" formControlName="Quantity" (focus)="autoBlur($event, 5000)" (blur)="clearAutoBlur($event)" (keydown)="onQuantityKeydown(orderForm, detail, $event, quantityFormat)" (keyup)="onQuantityChanged(orderForm, detail, $event, quantityFormat)">
                                            <div class="pos-unit" style="white-space: nowrap;">{{detail.value.Unit | objecttext}}</div>
                                            <div *ngIf="detail.value.AccessNumbers && detail.value.AccessNumbers.length > 0" class="pos-access-numbers">Số truy xuất: {{detail.value.AccessNumbers | objectstext}}</div>
                                            <ng-container *ngIf="detail.value.DiscountPercent > 0; else normalDetail">
                                                <div class="pos-price">
                                                    <span style="text-decoration: line-through; font-size: 0.8rem; color: var(--purple)">{{detail.value.ListedPrice | currency:'VND'}}</span>
                                                    <span style="font-size: 0.8rem; color: var(--red)">-{{detail.value.DiscountPercent | number: '1.0-2'}}%</span>
                                                </div>
                                            </ng-container>
                                            <div class="pos-price">x {{detail.value.Price | currency:'VND'}}</div>
                                            <!-- <div class="pos-discount">Giảm giá: {{detail.value.DiscountPercent | number}} đ</div> -->
                                            <hr style="margin: 0.5rem;">
                                            <div class="pos-tomoney">= {{detail.value.ToMoney | currency:'VND'}}</div>
                                        </td>
                                        <td style="text-align: center" class="sorting-handle">
                                            <button nbButton status="success" size="large" style="margin: 0.2rem" (click)="onIncreaseQuantityClick(orderForm, detail)">
                                                <nb-icon icon="plus-outline"></nb-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="pos-business" style="display: flex; flex-direction: column; padding: 0.5rem;">

                        <div>
                            <div class="row">
                                <div class="col-xxxl-12">
                                    <div class="pos-info">
                                        <div *ngIf="orderForm.value?.Code" style="white-space: nowrap; font-size: 0.9rem;" [ngClass]="{'text-color-danger': (orderForm['voucherType'] == 'COMMERCEPOSRETURN' || orderForm.value['State'] == 'UNRECORDED'),'text-color-success':(orderForm['voucherType'] == 'COMMERCEPOSORDER' && orderForm.value['State'] != 'UNRECORDED')}">Mã đơn:<br>{{orderForm.value?.Code}}{{orderForm.value['Thread'] ? (' ('+orderForm.value['Thread']+')') : ''}}</div>
                                        <div *ngIf="!orderForm.value?.Code" style="white-space: nowrap; font-size: 0.9rem;" class="text-color-warning">Đang khởi tạo...</div>
                                        <div *ngIf="orderForm.value?.DateOfSale || orderForm.value?.DateOfReturn" style="white-space: nowrap; font-size: 0.9rem;" class="text-color-primary">{{(orderForm.value?.DateOfSale || orderForm.value?.DateOfReturn) | date:'short'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="scrollable-container" style="flex: 1; overflow-x: hidden; max-width: initial; margin: initial;">
                            <div class="row">
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="chooseCustomer()">
                                        <nb-icon icon="person-outline"></nb-icon>
                                        <div class="button-label">Khách hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" status="{{orderForm.value?.PaymentMethod?.status || 'basic'}}" class="pos-business-button" (click)="switchPaymentMethod(orderForm)">
                                        <div>
                                            <nb-icon icon="credit-card-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F7</span>
                                        </div>
                                        <div class="button-label">{{orderForm.value?.PaymentMethod | objecttext}}</div>
                                    </button>
                                    <!-- <button nbButton fullWidth size="medium" status="primary" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" [outline]="true" *ngIf="!orderForm.value?.IsDebt" class="pos-business-button" (click)="toggleDebt(orderForm)">
                                        <div>
                                            <nb-icon icon="book-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F7</span>
                                        </div>
                                        <div class="button-label">Tiền mặt</div>
                                    </button> -->
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="danger" class="pos-business-button" (click)="onMakeNewReturnsForm()">
                                        <div>
                                            <nb-icon icon="undo-outline"></nb-icon>
                                            <!-- <span style="font-weight: bold; font-size: 1rem">F10</span> -->
                                        </div>
                                        <div class="button-label">Trả hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="openDeploymentForm(orderForm)">
                                        <nb-icon icon="archive-outline"></nb-icon>
                                        <div class="button-label">Triển khai</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm.value?.State === 'APPROVED'" status="info" class="pos-business-button" (click)="saveAsPriceReport(orderForm, {printType: 'PRICEREPORT'})">
                                        <nb-icon icon="file-text-outline"></nb-icon>
                                        <div class="button-label">Báo giá</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="primary" class="pos-business-button" (click)="makeNewOrder()">
                                        <div>
                                            <nb-icon icon="plus-square-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F5</span>
                                        </div>
                                        <div class="button-label">Đơn mới</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="orderForm.value?.State !== 'APPROVED'" status="primary" class="pos-business-button" (click)="print(orderForm, {printType: 'RETAILINVOICE'})">
                                        <div>
                                            <nb-icon icon="printer-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F9</span>
                                        </div>
                                        <div class="button-label">In lại</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="warning" class="pos-business-button" (click)="outOfStockReport() && false">
                                        <div>
                                            <nb-icon icon="archive-outline"></nb-icon>
                                            <span style="font-weight: bold; font-size: 1rem">F9</span>
                                        </div>
                                        <div class="button-label">Báo hết hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="!(orderForm.value.Object | objectid)" status="danger" class="pos-business-button" (click)="distributeMemberCard(orderForm)">
                                        <nb-icon icon="credit-card-outline"></nb-icon>
                                        <div class="button-label">Cấp phát thẻ</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="openBarcodeProcessHistory()">
                                        <nb-icon icon="clock-outline"></nb-icon>
                                        <div class="button-label">Lịch sử quét</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" status="info" class="pos-business-button" (click)="setThread(orderForm)">
                                        <nb-icon icon="layers-outline"></nb-icon>
                                        <div class="button-label">Luồng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Ghi chú</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Thuế VAT</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tiền phí</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Hàng hóa</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tồn kho</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Nhân viên</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đặt hàng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Lưu tạm</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">--</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="info" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Mở rộng</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đổi trả</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Báo cáo</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Mở két</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Khóa</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Đổi user</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Quản lý</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Fullscreen</div>
                                    </button>
                                </div>
                                <div class="col-xxxl-4">
                                    <button nbButton fullWidth size="medium" [disabled]="true" status="primary" class="pos-business-button">
                                        <nb-icon icon="square-outline"></nb-icon>
                                        <div class="button-label">Tùy chọn</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 1rem">
                            <!-- <div class="row pos-contact">
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px">
                                        <nb-badge text="Số điện thoại (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectPhone" #ObjectPhone formControlName="ObjectPhone" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onObjectPhoneInput(orderForm, $event)" type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Số điện thoại..." class="pos-contact-field-input">
                                    </div>
                                </div>
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px">
                                        <nb-badge text="Tên khách hàng (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectName" #ObjectName formControlName="ObjectName" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" status="primary" fieldSize="large" fullWidth placeholder="Tên khách hàng..." class="pos-contact-field-input">
                                    </div>
                                </div>
                                <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                    <div class="pos-contact-field" style="padding-top: 10px; margin-bottom: 5px;">
                                        <nb-badge text="Địa chỉ (F8)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                        <input style="padding-left: 0.5rem; padding-right: 0.5rem" id="ObjectAddress" #ObjectAddress formControlName="ObjectAddress" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput (keyup)="onObjectNameInput(orderForm, $event)" status="primary" fieldSize="large" fullWidth placeholder="Địa chỉ..." class="pos-contact-field-input">
                                    </div>
                                </div>
                            </div> -->
                            <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER'">
                                <div class="row" style="padding-bottom: 0.5rem;">
                                    <div class="col-xxxl-12">
                                        <div class="pos-total-payment" style="text-align: right; margin-top: 5px; padding-top: 1rem; padding-bottom: 1rem; border-color: var(--info)">
                                            <nb-badge text="Tạm tính" status="info" position="top left" style="top: -5px; left: 5px; font-size: 0.8rem;"></nb-badge>
                                            <div style="color: var(--info)">{{orderForm.value?.Amount | currency:'VND'}}</div>
                                            <ng-container *ngIf="orderForm.value.State != 'APPROVED' && orderForm.value.Object?.id && orderForm.value.Object?.id != 'POSCUSTOMER'">
                                                <div *ngIf="orderForm['ReceivableDebt'] > 0" style="color: var(--warning);">Công nợ:<br>{{orderForm['ReceivableDebt'] | currency:'VND'}}</div>
                                                <div *ngIf="orderForm['ReceivableDebt'] < 0" style="color: var(--warning);">Tạm ứng:<br>{{- orderForm['ReceivableDebt'] | currency:'VND'}}</div>
                                            </ng-container>
                                            <div *ngIf="orderForm.returnsObj">
                                                <div style="color: var(--warning);">{{orderForm['returnsObj'] && (orderForm['returnsObj'].value?.PaymentMethod | objectid) === 'DEBT' ? 'Giảm nợ' : 'Hoàn tiền'}}:<br>{{orderForm['returnsObj']?.value.Amount | currency:'VND'}}</div>
                                                <ng-container *ngIf="orderForm['returnsObj'] && (orderForm['returnsObj'].value?.PaymentMethod | objectid) === 'DEBT'">
                                                    <div style="color: var(--danger)">Tạm thu:<br>{{orderForm.value?.Amount | currency:'VND'}}</div>
                                                </ng-container>
                                                <ng-container *ngIf="!orderForm['returnsObj'] || (orderForm['returnsObj'].value?.PaymentMethod | objectid) !== 'DEBT'">
                                                    <div style="color: var(--danger)">{{orderForm.value?.Amount - orderForm['returnsObj']?.value.Amount < 0 ? 'Tạm trả' : 'Tạm thu' }}:<br>{{(orderForm.value?.Amount - orderForm['returnsObj']?.value.Amount < 0 ? -(orderForm.value?.Amount - orderForm['returnsObj']?.value.Amount) : (orderForm.value?.Amount - orderForm['returnsObj']?.value.Amount)) | currency:'VND'}}</div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" style="margin-bottom: 0.5rem;">
                                        <div class="pos-contact-field" style="padding-top: 10px; margin-bottom: 5px;">
                                            <nb-badge text="Giảm tổng đơn (F11)" status="success" position="top left" style="left: 5px;"></nb-badge>
                                            <nb-badge text="{{orderForm.value.Amount && orderForm.value.DecreaseForTotal && ((orderForm.value.DecreaseForTotal || 0)/orderForm.value.Amount*100).toFixed(2)}}%" status="danger" position="top right"></nb-badge>
                                            <input id="DecreaseForTotal" style="color: var(--success) !important; padding: 0.5rem;" #DecreaseForTotal formControlName="DecreaseForTotal" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onCashReceiptChanged(orderForm)" type="text" nbInput status="success" fieldSize="large" fullWidth placeholder="Giảm tổng đơn" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <ng-container>
                                            <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                <nb-badge text="{{orderForm['isReceipt'] ? (orderForm.value?.IsDebt ? 'Ghi nợ' : 'Phải thu') : (orderForm.value?.IsDebt ? 'Giảm nợ' : 'Phải trả')}}" status="danger" position="top left" style="left: 5px; font-size: 0.8rem;"></nb-badge>
                                                <div>{{ (orderForm['isReceipt']? (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) : -(orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds))) | currency:'VND'}}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="orderForm.value?.PaymentMethod?.id === 'CASH'">
                                        <div class="pos-contact-field cash-receipt-area" style="padding-top: 10px">
                                            <div class="cash-receipt-wrap" *ngIf="isShowCashPad">
                                                <div id="numPad">
                                                    <nb-card>
                                                        <nb-card-header>Chọn mệnh giá</nb-card-header>
                                                        <nb-card-body style="padding: 0.6rem;">
                                                            <div class="row">
                                                                <div class="col-is-4"><button nbButton fullWidth size="large" status="info" (click)="setCashReceipt(500000)">{{500000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="large" status="warning" (click)="setCashReceipt(200000)">{{200000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="large" status="success" (click)="setCashReceipt(100000)">{{100000 | currency:'VND'}}</button></div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-is-4"><button nbButton fullWidth size="medium" status="danger" (click)="setCashReceipt(50000)">{{50000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="medium" status="primary" (click)="setCashReceipt(20000)">{{20000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="medium" status="warning" (click)="setCashReceipt(10000)">{{10000 | currency:'VND'}}</button></div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-is-4"><button nbButton fullWidth size="small" status="primary" (click)="setCashReceipt(5000)">{{5000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="small" (click)="setCashReceipt(2000)">{{2000 | currency:'VND'}}</button></div>
                                                                <div class="col-is-4"><button nbButton fullWidth size="small" (click)="setCashReceipt(1000)">{{1000 | currency:'VND'}}</button></div>
                                                            </div>
                                                        </nb-card-body>
                                                    </nb-card>
                                                </div>
                                            </div>
                                            <nb-badge text="Khách đưa (F11)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                            <input type="hidden" id="CashReceipt" style="color: var(--primary) !important; padding: 0.5rem;" #CashReceipt formControlName="CashReceipt" (focus)="onCashReceiptFocus($event)" (blur)="onCashReceiptBlur($event)" (keyup)="onCashReceiptChanged(orderForm)" nbInput status="primary" fieldSize="large" fullWidth placeholder="Tiền mặt" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat" autocomplete="off">

                                            <input #autoInputCashReceipt nbInput type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Khách đưa" (keyup)="onCashReceipSearch($event.currentTarget.value)" [nbAutocomplete]="auto" currencyMask [options]="toMoneyCurencyFormat" />
                                            <nb-autocomplete #auto (selectedChange)="setCashReceipt($event)">
                                                <!-- <nb-option [value]="500000 | currency:'VND'">{{500000 | currency:'VND'}}</nb-option>
                                                <nb-option [value]="200000 | currency:'VND'">{{200000 | currency:'VND'}}</nb-option>
                                                <nb-option [value]="100000 | currency:'VND'">{{100000 | currency:'VND'}}</nb-option> -->

                                                <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">{{ option }}</nb-option>
                                            </nb-autocomplete>

                                            <!-- <div class="ng-autocomplete" id="cashreceipt-autocomplete">
                                                <ng-autocomplete
                                                    #cashreceiptAutocomplete
                                                    [data]="cashList"
                                                    [searchKeyword]="'text'"
                                                    placeholder="Khách đưa"
                                                    (selected)="onCashReceipt(orderForm, $event)"
                                                    (inputChanged)="onCashReceipt(orderForm, $event)"
                                                    [itemTemplate]="itemTemplate"
                                                  >
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.text"></a>
                                                </ng-template>

                                            </div> -->

                                        </div>
                                    </div>
                                    <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="(orderForm.value?.PaymentMethod?.id === 'MIXED')">
                                        <div class="pos-contact-field" style="padding-top: 10px">
                                            <nb-badge text="Tiền mặt (F11)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                            <input id="CashAmount" style="color: var(--primary) !important; padding: 0.5rem;" #CashReceipt formControlName="CashAmount" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onCashReceiptChanged(orderForm)" type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Tiền mặt" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                        </div>
                                    </div>
                                    <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="orderForm.value?.PaymentMethod?.id === 'MIXED'">
                                        <div class="pos-contact-field" style="padding-top: 10px">
                                            <nb-badge text="Chuyển khoản (F11)" status="primary" position="top left" style="left: 5px;"></nb-badge>
                                            <input id="CashTransferAmount" style="color: var(--primary) !important; padding: 0.5rem;" #CashReceipt formControlName="CashTransferAmount" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" (keyup)="onCashReceiptChanged(orderForm)" type="text" nbInput status="primary" fieldSize="large" fullWidth placeholder="Chuyển khoản" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                        </div>
                                    </div>
                                    <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="(orderForm.value?.PaymentMethod?.id === 'BANKTRANSFER' || orderForm.value?.PaymentMethod?.id === 'MIXED')">
                                        <div class="pos-contact-field" style="padding-top: 10px">
                                            <nb-badge text="Tài khoản" status="primary" position="top left" style="left: 5px; z-index: 1"></nb-badge>
                                            <ngx-select2 class="" formControlName="ReceiptBankAccount" [select2Option]="select2OptionForBankAccount" [data]="bankAccountList" (selectChange)="onObjectChange(orderForm, $event)"></ngx-select2>
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="orderForm.value?.PaymentMethod?.id === 'CASH'">
                                        <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; border-color: var(--warning); margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                            <nb-badge text="Thối lại" status="warning" position="top left" style="left: 5px;"></nb-badge>
                                            <div style="color: var(--warning)">{{((orderForm.cashBack < 0 ? -orderForm.cashBack : orderForm.cashBack) || 0) | currency:'VND'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="orderForm.value?.PaymentMethod?.id === 'MIXED' && orderForm.cashBack < 0">
                                            <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; border-color: var(--warning); margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                <nb-badge text="Công nợ" status="warning" position="top left" style="left: 5px;"></nb-badge>
                                                <div style="color: var(--warning)">{{((orderForm.cashBack < 0 ? -orderForm.cashBack : orderForm.cashBack) || 0) | currency:'VND'}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="margin-bottom: 0.5rem;" *ngIf="orderForm.value?.PaymentMethod?.id === 'MIXED' && orderForm.cashBack >= 0">
                                                <div class="pos-contact-field" style="padding-top: 10px; border-color: var(--warning);">
                                                    <nb-badge text="Thối lại" status="warning" position="top left" style="left: 5px;"></nb-badge>
                                                    <nb-badge text="x" status="danger" position="top right" style="right: 5px; cursor: pointer" nbTooltip="Ứng trước tiền hàng" (click)="removeCashBack(orderForm)"></nb-badge>
                                                    <input id="CashBack" style="color: var(--warning) !important; padding: 0.5rem;" formControlName="CashBack" (focus)="autoBlur($event, 10000)" (blur)="clearAutoBlur($event)" type="text" nbInput status="warning" fieldSize="large" fullWidth placeholder="Thối lại/Công nợ/Tạm ứng trước tiền hàng" nbTooltip="Thối lại/Công nợ/Tạm ứng trước tiền hàng" class="pos-contact-field-input" currencyMask [options]="toMoneyCurencyFormat">
                                                </div>
                                            </div>
                                            <div class="col-md-12" *ngIf="orderForm.value?.PaymentMethod?.id === 'MIXED'">
                                                <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; border-color: var(--info); margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                    <nb-badge text="Khách ứng trước" status="info" position="top left" style="left: 5px;"></nb-badge>
                                                    <div style="color: var(--info)">{{ (((orderForm.value?.CashAmount + orderForm.value?.CashTransferAmount) - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal) - orderForm.value?.CashBack) > 0 ? ((orderForm.value?.CashAmount + orderForm.value?.CashTransferAmount) - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal) - orderForm.value?.CashBack) : 0) | currency:'VND'}}</div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-12" *ngIf="(orderForm.value?.PaymentMethod?.id !== 'DEBT')">
                                            <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; border-color: var(--warning); margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                <nb-badge text="Thối lại" status="warning" position="top left" style="left: 5px;"></nb-badge>
                                                <div style="color: var(--warning)">{{orderForm.value?.CashReceipt - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) > 0 ? (orderForm.value?.CashReceipt - (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) | currency:'VND') : '--'}}</div>
                                            </div>
                                        </div> -->
                                            <div class="col-xxxl-8">
                                                <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" status="success" class="pos-business-button" (click)="payment(orderForm, {printType: 'RETAILINVOICE'})">
                                                    <div>
                                                        <nb-icon icon="flash-outline"></nb-icon>
                                                        <span style="font-weight: bold; font-size: 1rem">F9</span>
                                                    </div>
                                                    <div class="button-label">{{(orderForm.value?.PaymentMethod?.id === 'DEBT' ? 'Ghi nợ' : 'Thanh toán')}}</div>
                                                </button>
                                            </div>
                                            <div class="col-xxxl-4">
                                                <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State != 'APPROVED'" status="danger" class="pos-business-button" (click)="destroyOrder($event)">
                                                    <div>
                                                        <nb-icon icon="close-outline"></nb-icon>
                                                        <span style="font-weight: bold; font-size: 1rem">F4</span>
                                                    </div>
                                                    <div class="button-label">Hủy phiếu</div>
                                                </button>
                                            </div>
                                        </div>
                            </ng-container>
                            <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSRETURN'">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSRETURN'">
                                            <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                <nb-badge text="{{orderForm.value?.IsDebt ? 'Giảm nợ' : 'Phải trả'}}" status="danger" position="top left" style="left: 5px; font-size: 0.8rem;"></nb-badge>
                                                <div>{{ orderForm.value?.Amount | currency:'VND'}}</div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="orderForm['voucherType'] == 'COMMERCEPOSORDER'">
                                            <div class="pos-total-payment" style="text-align: right; padding-top: 0.5rem; padding-bottom: 0.5rem; margin-bottom: 0.5rem; margin-top: 0.6rem;">
                                                <nb-badge text="{{orderForm['isReceipt'] ? (orderForm.value?.IsDebt ? 'Ghi nợ' : 'Phải thu') : (orderForm.value?.IsDebt ? 'Giảm nợ' : 'Phải trả')}}" status="danger" position="top left" style="left: 5px; font-size: 0.8rem;"></nb-badge>
                                                <div>{{ (orderForm['isReceipt']? (orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds)) : -(orderForm.value?.Amount - orderForm.value?.DecreaseForTotal - (orderForm['returnsObj'] && orderForm['returnsObj'].IsDebt ? 0 : orderForm.value?.DebitFunds))) | currency:'VND'}}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-xxxl-8">
                                        <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State == 'APPROVED'" status="success" class="pos-business-button" (click)="payment(orderForm, {printType: 'RETAILINVOICE'})">
                                            <div>
                                                <nb-icon icon="flash-outline"></nb-icon>
                                                <span style="font-weight: bold; font-size: 1rem">F9</span>
                                            </div>
                                            <div class="button-label">{{orderForm['voucherType'] == 'COMMERCEPOSRETURN' ? (orderForm.value?.PaymentMethod?.id === 'DEBT' ? 'Giảm nợ' : 'Hoàn tiền') : (orderForm.value?.PaymentMethod?.id === 'DEBT' ? 'Ghi nợ' : 'Thanh toán')}}</div>
                                        </button>
                                    </div>
                                    <div class="col-xxxl-4">
                                        <button nbButton fullWidth size="medium" [disabled]="orderForm['isProcessing'] || orderForm.value?.State != 'APPROVED'" status="danger" class="pos-business-button" (click)="destroyOrder($event)">
                                            <div>
                                                <nb-icon icon="close-outline"></nb-icon>
                                                <span style="font-weight: bold; font-size: 1rem">F4</span>
                                            </div>
                                            <div class="button-label">Hủy phiếu</div>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                            <div style="display: flex;">
                                <div style="flex: 1; padding: 0.1rem;">
                                    <button nbButton fullWidth style="flex-direction: column; padding: 0.5rem;" size="medium" status="warning" class="pos-business-button" (click)="onPreviousOrderClick()">
                                        <nb-icon icon="arrow-left-outline"></nb-icon>
                                    </button>
                                </div>
                                <div style="flex: 1; padding: 0.1rem;">
                                    <button nbButton fullWidth style="flex-direction: column; padding: 0.5rem" [disabled]="orderForm['isProcessing'] || historyOrderIndex == historyOrders.length - 1" size="medium" status="warning" class="pos-business-button" (click)="onNextOrderClick()">
                                        <nb-icon icon="arrow-right-outline"></nb-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pos-status">
                    <nb-progress-bar *ngIf="progress > 0" [value]="progress" [status]="progressStatus" size="tiny" style="margin: 0.5rem;">{{progressLabel}}</nb-progress-bar>
                    <div *ngIf="progress == 0" style="display: flex; align-items: center; padding: 0.2rem; padding-left: 0.5rem; padding-right: 0.5rem; white-space: nowrap;">
                        <div class="status" style="flex: 1;">Commerce POS v1.0 | Nhân viên bán hàng: {{(cms.loginInfo$ | async)?.user?.Name}}</div>
                        <div class="pos-time" style="text-align: right; width: 350px;">{{currentDate | date:'short'}} - Trạng thái: {{ status || 'Ready'}}</div>
                    </div>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>